//import { mode, darken, whiten } from '@chakra-ui/theme-tools'
export const InputStyles = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    md: {
      field: {
        borderRadius: 'none'
      }
    }
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    filled: {
      field: {
        _focus: {
          borderColor: 'brand.100'
          //borderColor: 'secondary'
        }
      }
    }
  },
  // default values for `size` and `variant`
  defaultProps: {
    //size: '',
    variant: 'filled',
  },
}