/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
//import "@fontsource/anton"
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800&display=swap');
import "@fontsource/open-sans"
//import * as React from "react"
//import uuid from 'react-uuid'



window.dataLayer = window.dataLayer || [];

export function onRouteUpdate({ location, prevLocation }) {
    //console.log("Yeah", location.pathname)

    //const event_id = uuid()
    const event_id = null
    if (event_id) {
        if (process.env.NODE_ENV === `production` && typeof window !== 'undefined' && typeof window.fbq === `function`) {
            //alert(event_id);
            //console.log(event_id);

            //window.fbq('trackSingle', '360837731624314', 'PageView', {}, { eventID: `${event_id}` });
            //window.fbq('track', 'PageView', {}, { eventID: `${event_id}` });

            /* 
                        // Custom event trigger that fires after 2 seconds
                        setTimeout(() => {
                            dataLayer.push({
                                'event': 'my-custom-event'
                            });
                        }, 2000);
             */
            /* window.dataLayer.push({
                'event': 'spa_route_change',
                'route_change_event_uuid': `${event_id}`,
            }); */
        }

    } else {
        //console.log('Error: sin datos event_uuid en dataLayer.');
    }


}

/* const React = require("react")
exports.onRouteUpdate = ({ location, prevLocation }) => {
    console.log("Yeah", location.pathname)

} */

