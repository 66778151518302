export const CheckboxStyles = {
  // style object for base or default style
  baseStyle: {
    control: {
      borderRadius: 'none',
      _focus: {
        ring: 2,
        ringColor: 'brand.400'
      }
    }
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for `size` and `variant`
  defaultProps: {
    //size: '',
    //variant: '',
  },
}