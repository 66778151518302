import { extendTheme, theme as base, /* withDefaultColorScheme, withDefaultVariant */ } from "@chakra-ui/react"
//import { mode } from '@chakra-ui/theme-tools'

import { ButtonStyles as Button } from "./components/buttonStyles"
import { CheckboxStyles as Checkbox } from "./components/checkboxStyles"
import { InputStyles as Input } from "./components/inputStyles"

//Theme's tokens are: colors, fonts, breakpoints, spacing, sizes, components, styles.global
//see https://chakra-ui.com/docs/styled-system/theming/theme

const theme = extendTheme({
    fonts: {
        /* body: 'Open Sans, sans-serif',
        heading: 'Anton, sans-serif', */
        heading: `Poppins, ${base.fonts?.heading}`,
        body: `Poppins, ${base.fonts?.body}`
    },
    colors: {
        transparent: 'transparent',
        black: '#222222',
        white: '#fff',
        //test: 'red',
        primary: '#222222',
        secondary: '#9bdadd',
        textAccent: '#37465A',
        gradient: {
            red: {
                start: '#EC454F',
                end: '#F44881'
            },
            blue: {
                start: '#637BFF',
                end: '#21C8F6'
            },
            green: {
                start: '#1AAB8B',
                end: '#6EDCC4'
            },
            yellow: {
                start: '#F19A1A',
                end: '#FFC73C'
            },
            purple: {
                start: '#8B60ED',
                end: '#B372BD'
            }
        },
        brand: {
            50: "#222222",
            100: "#222222",
            200: "#222222",
            300: "#222222",
            400: "#222222",
            500: "#222222",
            600: "#222222",
            700: "#222222",
            800: "#222222",
            900: "#222222",
        },
        /* brand: {
            50: "#dff2ff",
            100: "#b2d6ff",
            200: "#85bbfa",
            300: "#56a0f5",
            400: "#2884f0",
            500: "#0f6bd7",
            600: "#0453a8",
            700: "#003b79",
            800: "#00244c",
            900: "#000d1f",
        }, */
        // ...
    },
    components: {
        Button,
        Checkbox,
        Input
    }
},
    /*  withDefaultColorScheme({
         colorScheme: "brand",
         //components: ['Button', 'Checkbox'] //incluye todos los componentes si no se incluye esto
     }),
     withDefaultVariant({
         variant: 'filled',
         components: ['Input']
     }) */
)

export default theme