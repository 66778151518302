import { mode, darken, whiten } from '@chakra-ui/theme-tools'
export const ButtonStyles = {
    // style object for base or default style
    baseStyle: {},
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {
        primary: (props) => ({
            rounded: 'none',
            _focus: {
                ring: 2,
                ringColor: 'brand.400'
            },
            color: mode('white', 'gray.800')(props),
            backgroundColor: mode('brand.500', 'brand.200')(props),
            _hover: {
                backgroundColor: mode('brand.600', 'brand.300')(props)
            },
            _active: {
                backgroundColor: mode('brand.700', 'brand.400')(props)
            }


        }),
        brandOutline: (props) => ({
            bg: "white",
            border: "2px solid",
            borderColor: "secodary",
            color: "black",
            borderRadius: "3xl",
            _hover: {
                bgColor: 'secondary',
                boxShadow: 'md',
                //transform: "scale(1.02)",
            },
            _focus: {
                //outline: 'none',
                boxShadow: 'none'
                //borderColor: 'secondary'
            },
            _active: {
                outline: 'none',
                //border: 'none',
                boxShadow: 'xl',
                transform: "scale(1.01)"

            }

        })
        /* primary2: (props) => ({
            bg: "primary",
            color: "white",
            _hover: mode(darken("primary", 20), whiten("primary", 20))(props),
            boxShadow: "md"
        }),
        secondary: (props) => ({
            bg: "secondary",
            color: "white",
            _hover: mode(darken("secondary", 20), whiten("secondary", 20))(props),
            boxShadow: "md"
        }), */
        /* secondaryOutline: (props) => ({
            bg: "transparent",
            border: "1px solid",
            borderColor: "secodary",
            color: "secondary",
            _hover: {
                boxShadow: 'md',
                transform: "scale(1.02)",
            }

        }) */
    },
    // default values for `size` and `variant`
    defaultProps: {
        //size: '',
        //variant: '',
    },
}